<template>
  <div :class="containerClass" @click="onWrapperClick">
    <AppTopBar @menu-toggle="onMenuToggle"/>
    <transition name="layout-sidebar">
      <div v-show="isSidebarVisible()" :class="sidebarClass" @click="onSidebarClick">
        <div class="layout-logo">
          <router-link to="/">
            <label style="font-weight: bold;font-size: 20px;">服贸协会<br/>公共服务运营系统</label>
          </router-link>
        </div>
        <AppProfile/>
        <AppMenu :model="menu" @menuitem-click="onMenuItemClick"/>
      </div>
    </transition>

    <div class="layout-main">
      <router-view/>
    </div>
  </div>
</template>

<script>
import AppTopBar from './backend/AppTopbar';
import AppProfile from './backend/AppProfile';
import AppMenu from './backend/AppMenu';
/*import AppConfig from './backend/AppConfig';*/

export default {
  data() {
    return {
      passwordShow: false,
      layoutMode: 'static',
      layoutColorMode: 'dark',
      staticMenuInactive: false,

      overlayMenuActive: false,
      mobileMenuActive: false,
      menu: [],
      adminMenu: [
        {
          label: '消息推送', icon: 'pi pi-fw pi-info-circle',
          items: [
            {label: '发送消息', icon: 'pi pi-fw pi-send', to: '/sendMessage'},
            {label: '消息列表', icon: 'pi pi-fw pi-list', to: '/messageList'},
            {label: '草稿箱', icon: 'pi pi-fw pi-book', to: '/drafts'}
          ]
        },
        {
          label: '智库管理', icon: 'pi pi-fw pi-users',
          items: [
            {label: '专家管理', icon: 'pi pi-fw pi-users', to: '/expertManagement'},
            {label: '履历审核', icon: 'pi pi-fw pi-shield', to: '/expertResume'},
            {label: '政策解读', icon: 'pi pi-fw pi-compass', to: '/policyInterpretation'},
            {label: '约稿', icon: 'pi pi-fw pi-file', to: '/problemAllocation?state=2'},
          ]
        },
        {
          label: '企业管理', icon: 'pi pi-fw pi-sitemap',
          items: [
            {label: '企业列表', icon: 'pi pi-fw pi-list', to: '/enterpriseList'},
            {label: '诉求受理', icon: 'pi pi-fw pi-reply', to: '/EnterpriseNeedAcceptance'},
          ]
        },
        {
          label: '招聘管理', icon: 'pi pi-fw pi-discord',
          items: [
            {label: '招聘列表', icon: 'pi pi-fw pi-list', to: '/recruit/recruitList'},
            {label: '轮播图管理', icon: 'pi pi-fw pi-images', to: '/recruit/carouselChart'},
          ]
        },
        {
          label: '问题管理', icon: 'pi pi-fw pi-question-circle', to: '/problemAllocation'
          /*items: [
            /!*{label: '问题发布', icon: 'pi pi-fw pi-send', to: '/demandSeminar'},*!/
            {label: '问题列表', icon: 'pi pi-fw pi-list', to: '/problemAllocation'},
          ]*/
        },
        {
          label: '活动管理', icon: 'pi pi-fw pi-flag', to: '/activityList'
          /*items: [
            {label: '活动列表', icon: 'pi pi-fw pi-list', to: '/activityList'},
          ]*/
        },
        {
          label: '热点话题', icon: 'pi pi-fw pi-comments', to: '/hotTopList'
          /*items: [
            {label: '话题列表', icon: 'pi pi-fw pi-list', to: '/hotTopList'}
          ]*/
        },
        {
          label: '宣传内容', icon: 'pi pi-fw pi-briefcase',
          items: [
            {label: '轮播图管理', icon: 'pi pi-fw pi-images', to: '/bannerGraph'},
            {label: '宣传文管理', icon: 'pi pi-fw pi-list', to: '/articleManagement'},
            {label: '宣传文类别管理', icon: 'pi pi-fw pi-list', to: '/columnList'}
          ]
        },
        {
          label: '视频管理', icon: 'pi pi-fw pi-video',
          items: [
            {label: '录播管理', icon: 'pi pi-fw pi-sliders-v', to: '/videoList'},
          ]
        },
        {
          label: '数据统计', icon: 'pi pi-fw pi-chart-line',
          items: [
            {label: '新增企业入驻', icon: 'pi pi-fw pi-desktop', to: '/dataDiagram'},
            {label: '智库统计', icon: 'pi pi-fw pi-users', to: '/expertDiagram'},
            {label: '活动类型', icon: 'pi pi-fw pi-thumbs-up', to: '/eventType'},
            {label: '招聘数据', icon: 'pi pi-fw pi-book', to: '/recruitData'},
            {label: '诉求数据', icon: 'pi pi-fw pi-question', to: '/demandsData'}
          ]
        },
        {
          label: '调查问卷', icon: 'pi pi-fw pi-check-circle',
          items: [
            {label: '调查问卷', icon: 'pi pi-fw pi-calendar', to: '/questionnaire'},
            {label: '消息模板', icon: 'pi pi-fw pi-briefcase', to: '/messageTemplate'}
          ]
        },
        // {
        //   label: '资金管理',icon: 'pi pi-fw pi-chart-bar',to:'/funds'
        // },
        {
          label: '系统设置', icon: 'pi pi-fw pi-cog',
          items: [
            {label: '数据字典', icon: 'pi pi-fw pi-book', to: '/dataDictionaries'}
          ]
        }
      ],
      superAdmin: [
        {
          label: '用户管理', icon: 'pi pi-user',
          items: [
            {
              label: '协会用户列表', icon: 'pi pi-users', to: '/adminUserList'
            }, {
              label: '公众号用户列表', icon: 'pi pi-users', to: '/publicUser'
            }
          ]
        },
      ],
      companiesMenu: [
        {label: '企业认证', icon: 'pi pi-fw pi-home', to: '/CompaniesInformation'},
        {label: '消息管理', icon: 'pi pi-fw pi-bell', to: '/message'},
        {label: '诉求管理', icon: 'pi pi-fw pi-question-circle', to: '/EnterpriseNeedAcceptance'},
        {label: '活动管理', icon: 'pi pi-fw pi-flag', to: '/events'},
        // {
        //   label: '业务需求管理', icon: 'pi pi-fw pi-question-circle',
        //   items: [
        //     {label: '问题发布', icon: 'pi pi-fw pi-send', to: '/businessRequirements/RequirementsRelease'},
        //   ]
        // },
        {
          label: '招聘管理', icon: 'pi pi-fw pi-discord', to: '/recruit/recruitList'
          /*items: [
            {label: '招聘列表', icon: 'pi pi-fw pi-list', to: '/recruit/recruitList'},
          ]*/
        },
      ],
      beforeTime: null,
      unLoadTime: null,
    }
  },
  mounted() {
    this.getToken()
    window.addEventListener('unload', () => this.unloadHandler())
  },
  unmounted() {
    window.removeEventListener('unload', () => this.unloadHandler())
  },
  methods: {
    async unloadHandler() {
      await this.$http.get('/login/logOut').then(() => {
        localStorage.clear()
      })

    },
    getToken() {
      let url = window.location.href;
      if (url.indexOf("t=") != -1) {
        let token = url.substring(url.indexOf("=") + 1)
        localStorage.setItem('token', token)
      }

      this.$http.get('/user/show').then((res) => {

        if (res.data == null || res.data == '') {
          alert(1)
          location.href = this.$global_msg.webSite
        } else {
          let userType = res.data.userType;

          localStorage.setItem('userInfo', JSON.stringify(res.data))
          if (userType == 1) {
            this.$http.post('/adminUser/isSuperAdmin/' + res.data.userId).then((a) => {
              if (a.data) {
                this.menu = this.adminMenu.concat(this.superAdmin)
              } else {
                this.menu = this.adminMenu
              }
            })
          } else if (userType == 2) {
            this.menu = this.companiesMenu
          }
        }
      })
    },
    onWrapperClick() {
      if (!this.menuClick) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }
      this.menuClick = false;
    },
    onMenuToggle() {
      this.menuClick = true;

      if (this.isDesktop()) {
        if (this.layoutMode === 'overlay') {
          if (this.mobileMenuActive === true) {
            this.overlayMenuActive = true;
          }

          this.overlayMenuActive = !this.overlayMenuActive;
          this.mobileMenuActive = false;
        } else if (this.layoutMode === 'static') {
          this.staticMenuInactive = !this.staticMenuInactive;
        }
      } else {
        this.mobileMenuActive = !this.mobileMenuActive;
      }

      event.preventDefault();
    },
    onSidebarClick() {
      this.menuClick = true;
    },
    onMenuItemClick(event) {
      if (event.item && !event.item.items) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }
    },
    onLayoutChange(layoutMode) {
      this.layoutMode = layoutMode;
    },
    onLayoutColorChange(layoutColorMode) {
      this.layoutColorMode = layoutColorMode;
    },
    addClass(element, className) {
      if (element.classList)
        element.classList.add(className);
      else
        element.className += ' ' + className;
    },
    removeClass(element, className) {
      if (element.classList)
        element.classList.remove(className);
      else
        element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
    },
    isDesktop() {
      return window.innerWidth > 1024;
    },
    isSidebarVisible() {
      if (this.isDesktop()) {
        if (this.layoutMode === 'static')
          return !this.staticMenuInactive;
        else if (this.layoutMode === 'overlay')
          return this.overlayMenuActive;
        else
          return true;
      } else {
        return true;
      }
    },
  },
  computed: {
    containerClass() {
      return ['layout-wrapper', {
        'layout-overlay': this.layoutMode === 'overlay',
        'layout-static': this.layoutMode === 'static',
        'layout-static-sidebar-inactive': this.staticMenuInactive && this.layoutMode === 'static',
        'layout-overlay-sidebar-active': this.overlayMenuActive && this.layoutMode === 'overlay',
        'layout-mobile-sidebar-active': this.mobileMenuActive,
        'p-input-filled': this.$appState.inputStyle === 'filled',
        'p-ripple-disabled': this.$primevue.config.ripple === false
      }];
    },
    sidebarClass() {
      return ['layout-sidebar', {
        'layout-sidebar-dark': this.layoutColorMode === 'dark',
        'layout-sidebar-light': this.layoutColorMode === 'light'
      }];
    },
    logo() {
      return (this.layoutColorMode === 'dark') ? "assets/layout/images/logo-white.svg" : "assets/layout/images/logo.svg";
    }
  },
  beforeUpdate() {
    if (this.mobileMenuActive)
      this.addClass(document.body, 'body-overflow-hidden');
    else
      this.removeClass(document.body, 'body-overflow-hidden');
  },
  components: {
    'AppTopBar': AppTopBar,
    'AppProfile': AppProfile,
    'AppMenu': AppMenu,
    /*'AppConfig': AppConfig*/
  }
}
</script>

<style lang="scss">
.p-toast.p-toast-topright {
  z-index: 1000;
  top: 70px;
}
</style>
