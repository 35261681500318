<template>
  <div class="layout-topbar">
    <button class="p-link layout-menu-button" @click="onMenuToggle">
      <span class="pi pi-bars"></span>
    </button>
    <div class="layout-topbar-icons">
      <button v-if="news" class="p-link" @click="toNews">
        <span class="layout-topbar-icon pi pi-calendar"></span>
        <span class="layout-topbar-badge">{{ news }}</span>
      </button>
      <button @click="toUser" class="p-link">
        <span class="layout-topbar-item-text">User</span>
        <span class="layout-topbar-icon pi pi-user"></span>
      </button>
      <Button @click="changPassword" class="p-link">修改密码</Button>
      <Button @click="exit" class="p-link"> 退出登录</Button>
    </div>
  </div>
  <Dialog v-model:visible="show" header="修改密码" style="width: 30%">
    <div style="width: 100%;padding: 1rem">
      <div class="p-field">
        <label>原密码：</label>
        <InputText @blur="passwordVerify" v-model="oldPassword" type="password"></InputText>
        <span v-if="verify" style="color: red;font-size: 12px">原密码错误！</span>
      </div>
      <div class="p-field">
        <label>新密码：</label>
        <InputText v-model="newPassword" type="password"></InputText>
      </div>
      <div class="p-field">
        <label>再次确定：</label>
        <InputText @blur="againPwd" v-model="again" type="password"></InputText>
        <span v-if="xt" style="color: red;font-size: 12px">两次密码不相同</span>
      </div>
      <Button style="margin-left: 100px" @click="changPwd">确定修改</Button>
    </div>
  </Dialog>
  <Toast/>
</template>

<script>
export default {
  data() {
    return {
      news: null,
      show: false,
      oldPassword: null,
      newPassword: null,
      again: null,
      verify:false,
      xt:false
    }
  },
  mounted() {
    this.$http.get('/send/getNews').then((res) => {
      this.news = res.data
    })
  },
  methods: {
    changPwd(){
      this.$http.post('/adminUser/changePassword?password='+this.newPassword).then(()=>{
        this.show=false
        this.$toast.add({severity: 'success', summary: '成功', life: 3000});
      })
    },
    againPwd(){
      if(this.newPassword!=this.again){
        this.xt = true
      }
    },
    passwordVerify(){
      if(this.oldPassword!='') {
        this.$http.post('/adminUser/passwordVerify?password=' + this.oldPassword).then((res) => {
          this.verify = res.data
        })
      }
    },
    changPassword() {
      this.show = true
    },
    onMenuToggle(event) {
      this.$emit('menu-toggle', event);
    },
    toNews() {
      this.$router.push('/message')
    },
    toUser() {
      let userType = JSON.parse(localStorage.getItem('userInfo')).userType
      if (userType == 1) {
        this.$router.push('/userInformation')
      } else {
        this.$router.push('/CompaniesInformation')
      }

    },
    exit() {
      this.$http.get('/login/logOut').then(() => {
        location.href = this.$global_msg.webSite
        localStorage.clear()
      })
    },
  }
}
</script>
<style scoped>
.p-field label {
  width: 100px;
}

.p-field input {
  width: calc(100% - 100px);
}
</style>
